<template>
  <div class="section section__sitemap">
    <div class="section__wrapper">
      <div class="title-with-icon mb-4">
        <div class="title-with-icon__icon title-with-icon__icon_sitemap"></div>
        <div class="title-with-icon__title">Карта сайта</div>
      </div>
      <ul class="section__sitemap-list">
        <li><a href="">Главная</a>
          <ul>
            <li><a href="/about">О школе экспорта</a>
              <ul>
                <li><a href="/about#all">Общая информация</a></li>
                <li><a href="/about#courses">Наши курсы</a></li>
                <li><a href="/about#regions">Регионы участники</a></li>
              </ul>
            </li>
            <li><a href="/education">Обучение</a>
              <ul>
                <li><a href="/education#online">Онлайн программа</a></li>
                <li><a href="/education#corporate">Корпоративное обучение</a></li>
                <li><a href="/education#export">Экспортные семинары</a></li>
                <li><a href="/education#reu">Программа с РЭУ им. Г.В. ПЛЕХАНОВА</a></li>
              </ul>
            </li>
            <li><a href="/howto">Как учиться?</a></li>
            <li><a href="/sveden">Сведения об образовательной организации</a></li>
            <li><a href="/calendar">Календарь обучения</a></li>
            <li><a href="/tutors">Тренеры и наставники</a></li>
            <li><a href="/news">Новости</a></li>
            <li><a href="/documents">Библиотека</a></li>
            <li><a href="/faq">FAQ</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sitemap"
}
</script>

<style scoped>

</style>